header {
    height: 94vh;
    overflow: hidden;
    background-image: url('../../assets/pool_water.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    border-radius: 2rem;
    align-items: center;
    position: relative;
}

.header__container {
    width: 50%;
    gap: 10%;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.logo img {
    height: 100%;
}

.container.header__container {
    place-items: center;
}

.container.header__container h1 {
    font-size: 4rem;
    font-weight: 800;
    line-height: 30px;
}

.container.header__container h2 {
    font-size: 2.6rem;
    font-weight: 100;
}

.cta {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.btn {
    background-color: #FFC400;
    color: var(--color-light);
}

/* MEDIA QUERIES (MEDIUM DEVICES, TABLET) */
@media screen and (max-width: 1024px) {
    header {
        height: 80vh; /* Adjusted height for better visual balance */
    }

    .container.header__container h1 {
        font-size: 3.5rem; /* Slightly reduced font size for better readability */
    }

    .container.header__container h2 {
        font-size: 2rem; /* Slightly reduced font size for better readability */
    }
}

/* MEDIA QUERIES (SMALL DEVICES, PHONE) */
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .scroll__down {
        display: none;
    }

    .container.header__container {
        align-items: center;
        text-align: center;
    }

    .container.header__container h1 {
        font-size: 2.5rem;
    }

    .container.header__container h2 {
        font-size: 1.8rem;
        line-height: 1.2;
    }

    .cta {
        margin-top: 2rem;
    }
}