.editable-textarea {
  width: 100%;
  padding: 1rem;
  font-size: 0.9rem;
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  resize: vertical;
  min-height: 100px;
  margin: 0 auto;
  background: var(--color-bg-variant);
}
  
.save-button {
  padding: 5px;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  margin-bottom: 2rem;
}
  
.save-button:hover {
  background-color: var(--color-white);
  color: var(--color-bg);
}