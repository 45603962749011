@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&family=Poppins:wght@300;400;500;600&family=Work+Sans:wght@200;300;700&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    text-decoration: none;
}

:root {
    --color-bg: #c7f0ed;
    --color-bg-variant: #a4dedd;
    --color-primary: #0d928b;
    --color-primary-variant: #0d928b;
    --color-white: #003b44;
    --color-light: #003136;
    --transition: all 400ms ease;
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {width:7px;background:var(--color-bg-variant);}
::-webkit-scrollbar-thumb {background:var(--color-light);}
::-webkit-scrollbar,::-webkit-scrollbar-thumb {
    border:5px transparent;
}

body {
    font-family: "Poppins", sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    padding: 20px;
}

/* GENERAL STYLES */

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

section {
    margin-top: 8rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

section > h5 {
    font-size: 1rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

p {
    font-size: 1rem;
}

.btn {
    padding: 0.5rem;
    display: inline-block;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

.notification {
    position: fixed;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    color: var(--color-primary);
    padding: 1rem 2rem;
    border-radius: 2rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* MEDIA QUERIES (MEDIUM DEVICES, TABLET) */
 
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/* MEDIA QUERIES (SMALL DEVICES, PHONE) */
 
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }
}