nav {
    background: rgba(13,146,139,0.3);
    width: max-content;
    display:block;
    padding: 0.7rem 1.7rem;
    z-index: 1000;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    top: 2rem;
    gap: 0.8rem;
    border-radius:3rem;
    backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 2rem;
    display:flex;
    color: var(--color-light);
    font-size: 1.1rem;
}

nav a:hover {
    background: rgba(161,235,231,0.3);
}

nav a.active {
    background: var(--color-bg);
    color: var(--color-white);
}

/* MEDIA QUERIES (MEDIUM DEVICES, TABLET) */
@media screen and (max-width: 768px) {
    nav {
        padding: 0.5rem 1rem;
        gap: 0.6rem;
        top: 1rem;
    }

    nav a {
        padding: 0.7rem;
        font-size: 1rem;
    }
}

/* MEDIA QUERIES (SMALL DEVICES, PHONE) */
@media screen and (max-width: 480px) {
    nav {
        padding: 0.3rem 0.7rem;
        gap: 0.5rem;
        top: 0.5rem;
    }

    nav a {
        padding: 0.5rem;
        font-size: 0.7rem;
    }
}