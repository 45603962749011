.services__container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

#services {
    width: 75%;
    margin: 0 auto;
    margin-top: 7rem;
}

.service {
    background: var(--color-bg-variant);
    border-radius: 0 0 2rem 2rem;
    border: 1px solid var(--color-bg);
    height: fit-content;
    transition: var(--transition);
}

.service:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.service__head {
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0,0,0,0.1);
}

.service__head h3 {
    color: var(--color-bg);
    font-size: 1rem;
    text-align: center;
}

.service__list {
    padding: 2rem;
    align-items: center;
}

.service__list p {
    font-size: 1rem;
}

.image {
    border-radius: 2rem;
    border: 1px solid var(--color-primary-variant);
    overflow: hidden;
}

.two-columns {
    display: flex;
    align-items: flex-start;
}

.column-1 {
    flex: 1;
}

.column-2 {
    flex: 1;
    padding-left: 1rem;
}


/* MEDIA QUERIES (MEDIUM DEVICES, TABLET) */
@media screen and (max-width: 1024px) {
    #services {
        width: 90%;
        margin-top: 5rem;
    }
    
    .services__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .service {
        border-radius: 1rem;
    }

    .service__head {
        padding: 1.5rem;
    }

    .service__head h3 {
        font-size: 1.2rem;
    }

    .service__list {
        padding: 1.5rem;
    }
}

/* MEDIA QUERIES (SMALL DEVICES, PHONE) */
@media screen and (max-width: 600px) {

    .two-columns {
        flex-direction: column;
        align-items: center;
    }
    
    .column-2 {
        padding-left: 0; /* Reset padding for small screens */
    }

    #services {
        width: 100%;
        margin-top: 3rem;
    }

    .services__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .service {
        border-radius: 1rem;
        border: none;
    }

    .service__head {
        padding: 1.2rem;
    }

    .service__head h3 {
        font-size: 1rem;
    }

    .service__list {
        padding: 1.2rem;
    }

    .image {
        border-radius: 1rem;
        border: 1px solid var(--color-primary-variant);
    }
}