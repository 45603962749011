.admin-login {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}

.auth-button {
  padding: 0.5rem;
  border-radius: 2rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  font-family: "Poppins", sans-serif;
  font-size: 0.7rem;
  background: var(--color-primary);
}

.auth-button:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}