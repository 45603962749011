.container.products__container {
    width: 50%;
    padding-bottom:4rem;
}

.product {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.product__desc {
    color: var(--color-light);
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
}

.swiper-button-prev::after,
.swiper-button-next::after {
    color: var(--color-primary);
    transform: scale(0.7);
}

@media screen and (max-width: 1024px) {
    .container.products__container {
        width: 60%;
    }

}

/* MEDIA QUERIES (MEDIUM DEVICES, TABLET) */
 
@media screen and (max-width: 600px) {
    .container.products__container {
        width: var(--container-width-md);
    }

    .product__desc {
        width: var(--container-width-md);
    }
}

/* MEDIA QUERIES (SMALL DEVICES, PHONE) */
 
@media screen and (max-width: 600px) {
    .container.products__container {
        width: var(--container-width-sm);
    }

    .product__desc {
        width: var(--container-width-sm);
    }
}