.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about__me {
    width: 100%;
    overflow: hidden;
}

.about__me-video {
    width: 100%;
    object-fit: cover;
    border-radius: 2rem;
    border: 1px solid var(--color-primary);
}

.about__me-image:hover {
    transform: rotate(0);
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1.5rem;
}

.about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
    width: 50%;
    margin: 0 auto;
    margin-top: 2rem;
}

.hours {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0%;
    justify-items: center;
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about__card h5 {
    font-size: 1.2rem;
}

.about__card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.about__content p {
    margin: 1rem 0 2rem;
    color: var(--color-light);
}

.about__content p {
    font-size: 1rem;
}

.google-map {
    width: 50%;
    height: 30rem;
    border: 0;
    display: block;
    margin: 0 auto;
    border-radius: 2rem;
    border: solid 1px var(--color-primary);
    margin-top: 4rem;
}

/* MEDIA QUERIES (MEDIUM DEVICES, TABLET) */
@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }

    .about__card {
        width: 100%;
    }

    .google-map {
        width: 80%;
        height: 25rem;
    }
}

/* MEDIA QUERIES (SMALL DEVICES, PHONE) */
@media screen and (max-width: 600px) {
    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__cards {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0;
    }

    .google-map {
        width: 100%;
        height: 20rem;
        margin-top: 2rem;
    }
}